<template>
  <div>
    <h5 class="text-black mt-2 mb-3">
        <strong>Konsulentinfo:</strong>
        <a-button style="float: right;" @click="toggleEdit" v-if="['HReditUser'].some(r => $store.state.user.roles.indexOf(r) >= 0) && mode !== 'notEdit'">
        {{ $t('general.edit') }}
        </a-button>
    </h5>
    <div class="userinfo" v-if="!edit">
        <div class="row">
        <div class="col-md-12">
            Selskapsnavn: {{ consultant.companyName }}<br>
            Org. nr: {{ consultant.organizationNumber }}<br>
            Adresse: {{ consultant.companyAddress.addressLine1 }}, {{ consultant.companyAddress.postalCode }} {{ consultant.companyAddress.city }}<br>
            Email: {{ consultant.email }}<br>
            <span v-if="['HRseeUsersSensitive'].some(r => $store.state.user.roles.indexOf(r) >= 0)">{{ $t('userInfo.bankaccount') }}: {{ consultant.bankAccount }}<br></span>
            <slot></slot>
          </div>
        </div>
    </div>
    <a-form @submit.prevent="updateUser()" v-else>
        <div class="row">
        <div class="col-lg-6">
            <a-form-item label="Selskapsnavn">
            <a-input placeholder="Selskapsnavn" v-model="consultant.companyName" />
            </a-form-item>
        </div>
        <div class="col-lg-6">
            <a-form-item label="Org. nummer">
            <a-input placeholder="Org. nummer" v-model="consultant.organizationNumber" />
            </a-form-item>
        </div>
        <div class="col-lg-6">
            <a-form-item :label="$t('userInfo.email')">
            <a-input :placeholder="$t('userInfo.email')" type="email" v-model="consultant.email" />
            </a-form-item>
        </div>
        <div class="col-lg-6">
            <a-form-item :label="$t('userInfo.bankaccount')" >
            <a-input :placeholder="$t('userInfo.bankaccount')" v-model="consultant.bankAccount" :maxLength="11"/>
            </a-form-item>
        </div>
        <div class="col-md-6">
            <a-form-item :label="$t('userInfo.adress')">
            <a-input :placeholder="$t('userInfo.adress')" v-model="consultant.companyAddress.addressLine1"/>
            </a-form-item>
        </div>
        <div class="col-md-2">
            <a-form-item :label="$t('userInfo.zip')">
            <a-input v-model="consultant.companyAddress.postalCode" :maxLength="4" @change="findCity"/>
            </a-form-item>
        </div>
        <div class="col-md-4">
            <a-form-item :label="$t('userInfo.city')">
              {{ consultant.companyAddress.city }}
            </a-form-item>
        </div>
        </div>
        <div class="form-actions mt-0">
        <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3">{{ $t('general.submit') }}</a-button>
        <a-button htmlType="submit" @click.prevent="toggleEdit">{{ $t('general.cancel') }}</a-button>
        </div>
    </a-form>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import postalCodes from 'norway-postal-codes'

export default {
  props: ['consultant', 'mode', 'uid'],
  data: () => {
    return {
      edit: false,
      fnrValid: false,
      fnrError: false,
    }
  },
  mounted() {
    // if consultant is undefined set it to empty object
    if (!this.consultant) {
      this.consultant = {
        companyName: '',
        organizationNumber: '',
        companyAddress: {
          addressLine1: '',
          postalCode: '',
          city: '',
        },
        email: '',
        bankAccount: '',
      }
    }
  },
  methods: {
    toggleEdit() {
      this.edit = !this.edit
    },
    findCity() {
      if (this.consultant.companyAddress.postalCode.length === 4) {
        const city = postalCodes[this.consultant.companyAddress.postalCode]
        if (city) {
          this.consultant.companyAddress.city = city
        } else {
          this.openNotification('Feil postnummer', 'Postnummer finnes ikke, vennligst sjekk igjen')
        }
      } else {
        this.consultant.companyAddress.city = ''
      }
    },
    updateUser() {
      const consultant = this.consultant
      firestore.collection('users').doc(this.uid).update({ consultant })
        .then(() => {
          this.openNotification('Personalia oppdatert', 'Personalia har blitt opdatert')
          this.toggleEdit()
        }).catch(() => this.openNotification('Feil ved oppdatering', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere'))
    },
    openNotification(title, description) {
      this.$notification.open({
        message: title,
        description: description,
      })
    },
  },
}
</script>

<style scoped>
  .ant-input.validfield {
    border-color: green!important
  }
  .ant-input.invalidfield {
    border-color: red!important
  }
</style>
