<template>
  <div>
    <div>
      <a-button style="float: right;" @click="toggleEdit" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
      {{ $t('general.edit') }}
      </a-button>
      <a-button class="mr-2" style="float: right;" @click="$emit('newPosition')" v-if="['HRcreateUsersAll', 'HRcreateUsersDepartment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
      {{ $t('general.new') }}
      </a-button>
      <h5 class="text-black mt-2 mb-3">
        <strong>Stilling</strong>
      </h5>
      <div v-if="data.positions && data.positions.length > 0">
        <a-divider v-if="data.positions.length > 1"><a @click="showall = !showall">{{ showall ? 'Skjul' : 'Vis' }} tidligere</a></a-divider>
        <div v-for="(position, index) in data.positions" :key="index">
          <div class="mb-2" v-if="(position.status === 'active' || showall) && !editPosition">
            <strong>{{ position.type }}</strong><br>
            Selskap: {{ position.company }}<br>
            Prosjekt: {{ position.project }}<br>
            Avdeling: {{ position.department }}<br>
            Ansettelsestype: {{ position.employmentType }}<br>
            Stillingsprosent: {{ position.percentage }}<br>
            <div v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              PositionRef: {{ position.positionRef }}<br>
            </div>
            <div v-if="position.salary && ['HRseeSalary'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              Lønnsmodell: {{ salaryTypes.find(s => position.salary.type === s.value).label }} {{ position.salary.amount }} NOK<br>
            </div>
            Start dato: {{ position.startDate }}<br>
            Slutt dato: {{ position.endDate }}<br>
            <div v-if="position.endDate">
              Årsak sluttdato: {{ position.reason }}
            </div>
              Signert kontrakt: {{ position.signedContract ? 'Ja' : 'Nei' }}<br>
            <div v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              {{ position.signatureStatus }}
            </div>
            <div v-if="!position.signedContract">
              <a-button class="mt-2" @click="createContract(data, position, index)" v-if="!position.signRef" :loading="loading">
                Lag Kontrakt
              </a-button>
              <div v-else>
                <a-button class="mt-2" @click="createContract(data, position, index)" v-if="!signatureStatus(position.signRef, index)" :loading="loading">
                  Regenerer Kontrakt
                </a-button>
              </div>
            </div>
            <a-button style="float: right;" @click="deletePosition(index)" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0) && !position.signedContract">
            Slett Stilling
            </a-button>
            <div class="form-actions" v-if="data.positions.length != (index+1)"/>
          </div>
          <div class="mb-2" v-if="editPosition">
            <a-input v-model="position.type"/>
            Selskap:
            <a-input v-model="position.company"/>
            Prosjekt:
            <a-input v-model="position.project"/>
            Avdeling:
            <a-input v-model="position.department"/>
            Ansettelsestype:
            <a-input v-model="position.employmentType"/>
            Stillingsprosent:
            <a-input v-model="position.percentage"/>
            <div v-if="['HRseeSalary'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              Lønnsmodell:<br>
              <a-select v-model="position.salary.type" :options="salaryTypes" style="width: 200px"/><br>
              Beløp:
              <a-input v-model="position.salary.amount"/>
            </div>
            Start dato:
            <a-date-picker v-model="position.startDate"/> <br>
            Slutt dato:
            <a-date-picker v-model="position.endDate"/> <br>
            <div v-if="position.endDate">
              Årsak sluttdato:
              <a-select
                v-model="position.reason"
                :options="options1"
                ref="select"
                style="width: 300px"
              ></a-select>
            </div>
            Signert kontrakt:
            <div v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-select v-model="position.signedContract" style="width: 120px">
                <a-select-option :value="true">
                  Ja
                </a-select-option>
                <a-select-option :value="false">
                  Nei
                </a-select-option>
              </a-select>
            </div>
            Status Stilling
            <div v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-select v-model="position.status" style="width: 120px">
                <a-select-option value="active">
                  Aktiv
                </a-select-option>
                <a-select-option value="inactive">
                  Inaktiv
                </a-select-option>
              </a-select>
            </div>
            <div v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              {{ position.signatureStatus }}
            </div>
            <a-button style="float: right;" @click="deletePosition(index)" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0) && !position.signedContract">
            Slett Stilling
            </a-button>
            <div class="form-actions" v-if="data.positions.length != (index+1)"/>
          </div>
        </div>
        <a-button class="mt-2" @click="updatePosition()" v-if="editPosition">
          Oppdater
        </a-button>
      </div>
      <span v-else>Ingen stilling registrert</span>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import moment from 'moment'

import { openNotification } from '@/services/powerAutomate'
import { createContract, updateEmployee, salaryTypes } from '@/services/common/hr'

const systems = ['Microsoft 365', 'Leaddesk Fjordkraft', 'Talkmore Privat']

export default {
  props: ['data'],
  data() {
    return {
      roles: this.$store.state.user.roles,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      editPosition: false,
      showall: false,
      systems,
      leadDesk: false,
      office365: false,
      talkmoreB2C: false,
      leadDeskUser: '',
      companyEmail: '',
      newPosition: false,
      position: {},
      positionsSelector: [],
      selectedContract: {},
      company: {},
      contractTypes: [],
      loading: false,
      salaryTypes,
      options1: [
        {
          value: 'Arbeidstaker har sagt opp selv',
          label: 'Arbeidstaker har sagt opp selv',
        },
        {
          value: 'Arbeidsgiver har sagt opp arbeidstaker',
          label: 'Arbeidsgiver har sagt opp arbeidstaker',
        },
        {
          value: 'Endring av stilling internt',
          label: 'Endring av stilling internt',
        },
        {
          value: 'Avskjed',
          label: 'Avskjed',
        },
      ],
    }
  },
  mounted() {
    this.data.positions.forEach((position, i) => {
      if (position.salary) return
      this.data.positions[i].salary = {}
    })
  },
  methods: {
    moment,
    createContract,
    toggleEdit() {
      this.editPosition = !this.editPosition
    },
    updatePosition() {
      const data = this.data
      for (let i = 0; i < data.positions.length; i++) {
        data.positions[i].startDate = moment(data.positions[i].startDate).format('YYYY-MM-DD')
        data.positions[i].endDate = data.positions[i].endDate ? moment(data.positions[i].endDate).format('YYYY-MM-DD') : ''
      }
      updateEmployee(data)
      this.toggleEdit()
    },
    deletePosition(i) {
      const data = this.data
      data.positions.splice(i, 1)
      firestore.collection('users').doc(this.data.uid).update(data).then(() => {
        openNotification('Stilling slettet')
        this.data = data
      }).catch(err => openNotification('Feil ved sletting av stilling', err))
    },
    signatureStatus(signRef, index) {
      if (signRef) {
        firestore.collection('signatures').doc(signRef).get().then(doc => {
          if (doc.exists) {
            const data = doc.data()
            if (data.status === 'signed') {
              this.data.positions[index].signatureStatus = 'Kontrakten er signert'
              this.data.positions[index].signedContract = true
              this.updateEmployee()
              this.$forceUpdate()
              return true
            }
            if (data.status === 'under approval') {
              this.data.positions[index].signatureStatus = 'Kontrakten er under godkjenning'
              this.$forceUpdate()
              return true
            }
            if (data.status === 'sendt for signature') {
              this.data.positions[index].signatureStatus = 'Kontrakten er sendt for signering'
              this.$forceUpdate()
              return true
            }
            if (data.status === 'rejected') {
              this.data.positions[index].signatureStatus = 'Kontrakten er avvist'
              this.$forceUpdate()
              return false
            }
            if (data.status === 'expired') {
              this.data.positions[index].signatureStatus = 'Kontrakten er utgått'
              this.$forceUpdate()
              return false
            }
            if (data.status === 'cancelled') {
              this.data.positions[index].signatureStatus = 'Kontrakten er avbrutt'
              this.$forceUpdate()
              return false
            }
          }
        })
      }
      return true
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>
