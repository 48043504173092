<template>
  <div>
        <h5 class="text-black mt-2 mb-3">
            <strong>Personalia</strong>
            <a-button style="float: right;" @click="toggleEdit" v-if="['HReditUser'].some(r => $store.state.user.roles.indexOf(r) >= 0) && mode !== 'notEdit'">
            {{ $t('general.edit') }}
            </a-button>
        </h5>
        <div class="userinfo" v-if="!edit">
            <div class="row">
            <div class="col-md-12">
                Ansattnummer: {{ data.employeeID }}<br>
                {{ $t('userInfo.name') }}: {{ data.name }}<br>
                {{ $t('userInfo.surname') }}: {{ data.surname }}<br>
                {{ $t('userInfo.birthdate') }}: {{ data.birthdate }}<br>
                {{ $t('userInfo.age') }}: {{ calculateAge(data.birthdate) }}<br>
                <!-- {{ $t('userInfo.citizenship') }}: {{ data.citizenship }}<br> -->
                <span v-if="['HRseeUsersSensitive'].some(r => $store.state.user.roles.indexOf(r) >= 0)">{{ $t('userInfo.socialsecurity') }}: {{ data.personalNumber }}<br></span>
                <span v-if="['HRseeUsersSensitive'].some(r => $store.state.user.roles.indexOf(r) >= 0)">{{ $t('userInfo.bankaccount') }}: {{ data.bankAccount }}<br></span>
                <slot></slot>
              </div>
            </div>
        </div>
        <a-form @submit.prevent="updateUser()" v-else>
            <div class="row">
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.name')">
                <a-input placeholder="Name" v-model="data.name" />
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.surname')">
                <a-input placeholder="Surname" v-model="data.surname" />
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.email')">
                <a-input :placeholder="$t('userInfo.email')" type="email" v-model="data.email" />
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.phone')">
                <phone-form-component v-model="data.phone"/>
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.socialsecurity')" >
                <a-input :placeholder="$t('userInfo.socialsecurity')"
                  v-model="data.personalNumber" :maxLength="11" @change="fnrValidate"
                  :class="{ validfield: fnrValid, invalidfield: fnrError }"
                  v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)"
                />
                <span v-else>{{ data.personalNumber }}<br></span>
                </a-form-item>
            </div>
            <div class="col-lg-6">
                <a-form-item :label="$t('userInfo.bankaccount')" >
                <a-input :placeholder="$t('userInfo.bankaccount')" v-model="data.bankAccount" :maxLength="11"/>
                </a-form-item>
            </div>
            <div class="col-md-6">
                <a-form-item :label="$t('userInfo.adress')">
                <a-input :placeholder="$t('userInfo.adress')" v-model="data.adress"/>
                </a-form-item>
            </div>
            <div class="col-md-2">
                <a-form-item :label="$t('userInfo.zip')">
                <a-input v-model="data.zip" :maxLength="4" @change="findCity"/>
                </a-form-item>
            </div>
            <div class="col-md-4">
                <a-form-item :label="$t('userInfo.city')">
                  {{ data.city }}
                </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Stilling">
              <a-input v-model="data.title"/>
              </a-form-item>
            </div>
            <div class="col-md-6" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Selskaper">
                <a-select mode="multiple" :options="companies" v-model="data.companies"/>
              </a-form-item>
            </div>
            <div class="col-md-6" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Avdeling">
              <a-input v-model="data.department"/>
              </a-form-item>
            </div>
            <div class="col-md-6" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Ansatt ID">
              <a-input v-model="data.employeeID"/>
              </a-form-item>
            </div>
            <div class="col-md-6" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Status">
                <a-select v-model="data.status">
                  <a-select-option value="active">
                    Aktiv
                  </a-select-option>
                  <a-select-option value="onboarding">
                    Onboarding
                  </a-select-option>
                  <a-select-option value="inactive">
                    Inaktiv
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-6" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Type">
                <a-select v-model="data.type">
                  <a-select-option value="employee">
                    Ansatt
                  </a-select-option>
                  <a-select-option value="consultant">
                    Konsulent
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <div class="col-md-6" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
              <a-form-item label="Prosjekt">
                <a-select
                  v-model="data.project"
                  v-decorator="[
                  'project',
                  ]"
                  :options="projects"
                />
              </a-form-item>
            </div>
            </div>
            <div class="form-actions mt-0">
            <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3">{{ $t('general.submit') }}</a-button>
            <a-button htmlType="submit" @click.prevent="toggleEdit">{{ $t('general.cancel') }}</a-button>
            </div>
        </a-form>
    </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import postalCodes from 'norway-postal-codes'
import validator from '@navikt/fnrvalidator'
import phoneFormComponent from '@/components/custom/forms/formComponents/telephone'

export default {
  props: ['data', 'mode'],
  components: {
    phoneFormComponent,
  },
  data: () => {
    return {
      edit: false,
      fnrValid: false,
      fnrError: false,
      companies: [],
      projects: [],
    }
  },
  mounted() {
    if (['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      firestore.collection('settings').doc('companies').collection('companies').get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            this.companies.push({
              value: doc.data().id,
              label: doc.data().displayName,
            })
          })
        })
      firestore.collection('settings').doc('projects').collection('projects').get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const project = {
              label: doc.data().name,
              value: doc.data().id,
            }
            this.projects.push(project)
          })
        })
    }
  },
  methods: {
    toggleEdit() {
      this.edit = !this.edit
      this.fnrValidate()
    },
    calculateAge (dob) {
      const birthday = new Date(dob)
      const diff = Date.now() - birthday.getTime()
      const ageDate = new Date(diff)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    findCity() {
      if (this.data.zip.length === 4) {
        const city = postalCodes[this.data.zip]
        if (city) {
          this.data.city = city
        } else {
          this.openNotification('Feil postnummer', 'Postnummer finnes ikke, vennligst sjekk igjen')
        }
      } else {
        this.data.city = ''
      }
    },
    calculateBirthday(fnr) {
      if (fnr.length > 5) {
        const day = fnr.slice(0, 2)
        const month = fnr.slice(2, 4)
        let year = fnr.slice(4, 6)
        if (year > 20) {
          year = 19 + year
        } else {
          year = 20 + year
        }
        this.data.birthdate = year.concat('-', month, '-', day)
      }
    },
    fnrValidate() {
      const data = this.data.personalNumber
      this.calculateBirthday(data)
      if (data.length === 11) {
        const result = validator.fnr(this.data.personalNumber)
        if (result.status === 'valid') {
          this.fnrError = false
          this.fnrValid = true
        } else {
          this.fnrValid = false
          this.fnrError = true
        }
      } else {
        this.fnrValid = false
        this.fnrError = true
      }
    },
    updateUser() {
      if (this.fnrValid) {
        const data = this.data
        data.fullName = data.name.concat(' ', data.surname)
        console.log(data)
        firestore.collection('users').doc(data.uid).set(data)
          .then(() => {
            this.openNotification('Personalia oppdatert', 'Personalia har blitt opdatert')
            this.toggleEdit()
          }).catch(() => this.openNotification('Feil ved oppdatering', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere'))
      } else {
        this.openNotification('Ugyldig Fødselsnummer eller d-nummer', 'Fødselsnummer eller D-nummer er ugyldig, vennligst rett opp.')
      }
    },
    openNotification(title, description) {
      this.$notification.open({
        message: title,
        description: description,
      })
      console.log('check')
    },
  },
}
</script>

<style scoped>
  .ant-input.validfield {
    border-color: green!important
  }
  .ant-input.invalidfield {
    border-color: red!important
  }
</style>
