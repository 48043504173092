<template>
  <div>
      <h5 class="text-black mt-4 mb-3">
      <strong>Kontakt informasjon</strong>
      </h5>
      {{ $t('userInfo.adress') }}: {{ data.adress }}, {{ data.zip }} {{ data.city }} <br>
      {{ $t('topBar.profileMenu.email') }}: <a :href="'mailto:'.concat(data.email)">{{ data.email }}</a><br>
      {{ $t('topBar.profileMenu.phone') }}: <a :href="'tel:'.concat(data.phone)">{{ addSpaces(data.phone) }}</a><br>
  </div>
</template>

<script>
export default {
  props: ['data'],
  methods: {
    addSpaces(f) {
      // const fVal = f.replace(/\D[^]/g, '')
      // f = fVal.slice(0, 3) + ' ' + fVal.slice(3, 5) + ' ' + fVal.slice(5)
      return f
    },
  },
}
</script>

<style scoped>
  .ant-input.validfield {
    border-color: green!important
  }
  .ant-input.invalidfield {
    border-color: red!important
  }
</style>
