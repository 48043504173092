<template>
  <div v-if="data">
    <div class="row">
        <div class="col-lg-12">
          <h2>Offboardingsskjema
          <a-button type="secondary" style="float: right;" class="mt-3" @click="cancelOffboarding">Avbryt offboarding</a-button>
          </h2>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <kit-general-10v1 :name="data.fullName" :position="data.title" :department="data.department" :uid="data.uid" :companies="data.companies" :type="data.type"/>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card">
          <div class="card-body">
            <about-user-form :data="data" mode="notEdit"/>
          </div>
          </div>
        </div>
      </div>
      <a-form @submit.prevent="deactivateUser2(data)">
        <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
                <a-form-item label="Sluttdato" :required="true">
                <a-date-picker  v-model="offboarding.endDate"/>
                </a-form-item>
            </div>
            <div class="col-md-4">
              <a-form-item label="Årsak" :required="true">
                <a-select
                  ref="select"
                  v-model="offboarding.reason"
                  :options="options1"
                  style="width: 300px"
                  @change="checkDismissal"
                ></a-select>
              </a-form-item>
            </div>
            <!-- <div class="col-md-4">
            </div> -->
          </div>
          <div class="row" v-if="dismissal">
            <div class="col-md-12">
              <a-textarea placeholder="Skriv inn årsak for avskjed" :rows="4" v-model="offboarding.dismissalReason"/>
            </div>
          </div>
        </div>
        </div>
        <div class="card">
          <div class="card-body">
            <upload-area
              ref="childComponent"
              :reference="data.employeeID"
              hint="Last opp all dokumentasjon ifm. oppsigelse"
              prependText="Oppsigelse"/>
          </div>
        </div>
        <a-button v-if="dismissal" style="width: 250px;" type="primary" class="mr-3" :loading="loadingOffboarding" @click.prevent="askDismissal">Send avskjed til godkjenning</a-button>
        <a-button v-else style="width: 150px;" type="primary" htmlType="submit" class="mr-3" :loading="loadingOffboarding">Offboard Ansatt</a-button>
      </a-form>
  </div>
</template>

<script>
import { firestore, functions, storage } from '@/services/firebase'
import { deactivateTelenorFiberField, deactivateTalkmoreUsers, deactivateFjordkraftPortal } from '@/services/common/hr'
import moment from 'moment'

import uploadArea from '@/components/custom/visual/uploadArea'
import KitGeneral10v1 from '@/components/kit/widgets/General/10v1/index'
import AboutUserForm from '@/components/custom/forms/userinfo'
import JsPDF from 'jspdf'

import { openNotification, removefromMailinglist, sendEmail, deactivate365API, deactivateLeaddeskAPI } from '@/services/powerAutomate'

export default {
  components: {
    uploadArea,
    KitGeneral10v1,
    AboutUserForm,
  },
  props: ['data'],
  data() {
    return {
      offboarding: {},
      loadingOffboarding: false,
      dismissal: false,
      options1: [
        {
          value: 'Arbeidstaker har sagt opp selv',
          label: 'Arbeidstaker har sagt opp selv',
        },
        {
          value: 'Arbeidsgiver har sagt opp arbeidstaker',
          label: 'Arbeidsgiver har sagt opp arbeidstaker',
        },
        {
          value: 'Avskjed',
          label: 'Avskjed',
        },
      ],
    }
  },
  methods: {
    moment,
    cancelOffboarding() {
      this.$refs.childComponent.cancelUpload()
      this.offboarding = {}
      this.$emit('cancelOffboarding')
    },
    checkDismissal() {
      if (this.offboarding.reason === 'Avskjed') {
        this.dismissal = true
      } else {
        this.dismissal = false
      }
    },
    async askDismissal() {
      if (this.offboarding.endDate && this.offboarding.reason && this.offboarding.dismissalReason) {
        this.loadingOffboarding = true
        const endDate = String(moment(this.offboarding.endDate).format('YYYY-MM-DD'))
        const date = String(moment(this.offboarding.endDate).format('YYYY-MM-DDTHH:mm'))
        const doc = await this.generateDismissal(this.data.fullName, this.data.adress.concat(', ', this.data.zip, ' ', this.data.city), this.offboarding.dismissalReason, String(moment().format('DD.MM.YYYY')))
        const storageRef = storage.ref('/tasks')
        const listRef = storageRef.child('tasksAttachments')
        const attachments = [
          {
            url: '',
            name: '',
            type: 'file',
          },
          {
            url: 'https://www.digipost.no/app/post#/send',
            name: 'Gå til digipost',
            type: 'link',
          },
          {
            url: `mailto:${this.data.email}?cc=${this.$store.state.user.email2}&bcc=styre@skygruppen.no&subject=Avskjedsbrev%20Skycall%20AS&body=Hei%2C%0D%0A%0D%0ASe%20vedlegg.%20Dette%20har%20ogs%C3%A5%20blitt%20sendt%20p%C3%A5%20Digipost.%0D%0A`,
            name: 'Send e-post',
            type: 'link',
          },
        ]
        listRef.child('avskjedsbrev'.concat(this.data.fullName)).put(doc).then((snapshot) => {
          console.log(snapshot.ref)
          attachments[0].name = snapshot.ref.fullPath
          return snapshot.ref.getDownloadURL()
        }).then(url => {
          attachments[0].url = url
          const taskRef = firestore.collection('tasks')
          return taskRef.add({
            title: `Send avskjedsbrev til ${this.data.fullName}`,
            description: `${this.$store.state.user.name} har søkt om å sende avskjed til ${this.data.fullName}<br>Vennligst last ned brevet dobbeltsjekk at den er riktig.<br>Etter det send gjerne på e-post og digipost.`,
            date,
            attachments,
            status: 'to-do',
            callbacks: [
              {
                fn: 'offboardUser',
                data: {
                  uid: this.data.uid,
                  reason: this.offboarding.reason,
                  endDate,
                  offboardBy: this.$store.state.user.name,
                },
                name: 'Bekreft avskjed',
              },
            ],
          })
        }).then(() => {
          openNotification('Avskjed sendt til godkjenning')
          this.$router.push({ path: '/hr/persons/active' })
        }).catch(err => {
          console.log(err)
          openNotification('Feil ved offboarding', 'Vennligst informer IT')
        }).finally(() => {
          this.loadingOffboarding = false
        })
      } else {
        openNotification('Vennligst legg inn alle obbligatoriske felt')
      }
    },
    async deactivateUser2() {
      this.loadingOffboarding = true
      const endDate = String(moment(this.offboarding.endDate).format('YYYY-MM-DD'))
      if (this.offboarding.endDate && this.offboarding.reason) {
        const offboardUser = functions.httpsCallable('offboardUser')
        offboardUser({
          uid: this.data.uid,
          reason: this.offboarding.reason,
          endDate: endDate,
          offboardBy: this.$store.state.user.name,
        }).then(res => {
          openNotification(res.data.text)
          this.$router.push({ path: '/hr/persons/active' })
        }).catch(err => {
          openNotification('Feil ved offboarding', 'Vennligst informer IT')
          console.log(err)
        }).finally(() => {
          this.loadingOffboarding = false
        })
      } else {
        this.loadingOffboarding = false
        openNotification('Vennligst legg inn alle obbligatoriske felt')
      }
    },
    async deactivateUser(data) {
      this.loadingOffboarding = true
      const date = String(moment().format('YYYY-MM-DD'))
      const endDate = String(moment(this.offboarding.endDate).format('YYYY-MM-DD'))
      if (this.offboarding.endDate && this.offboarding.reason) {
        removefromMailinglist(data.mailingListID)
          .then(() => {
            deactivate365API({ email: data.companyEmail })
            if (data.leadDesk) {
              deactivateLeaddeskAPI(data.leadDesk)
            }
            if (data.fjordkraftPortal) {
              // Add to scheduled sending Fjordkraft Deactivate
              data = deactivateFjordkraftPortal(data)
            }
            if (data.talkmoreB2C) {
              data = deactivateTalkmoreUsers(data)
            }
            if (data.telenorFiber) {
              data = deactivateTelenorFiberField(data)
            }
            // Add to scheduled sending Tripletex Deactivate
            firestore.collection('tasks').add({
              description: `Vennligst registrer oppsigelse brukeren til ${data.employeeID} ${data.fullName} i Tripletex
               <br> Hans siste arbeidsdag er ${endDate} med årsak ${this.offboarding.reason}`,
              icon: 'fa fa-group',
              type: 'tripletex',
              title: `Registrer oppsigelse til ${data.fullName} i Tripletex`,
              attachments: [
                {
                  url: 'https://tripletex.no/execute/listEmployees?contextId=32277385',
                  name: 'Gå til Tripletex Ansatte',
                  type: 'link',
                },
              ],
              status: 'to-do',
              date,
            })
            const slackRef = firestore.collection('tasks').doc('slack')
            slackRef.set({
              description: 'Vennligst deaktiver følgende brukere i Slack',
              icon: 'fa fa-slack',
              type: 'slackUserAdmin',
              title: 'Deaktivere Slack Brukere',
              attachments: [
                {
                  url: 'https://skygruppen.slack.com/admin',
                  name: 'Gå til Slack Admin',
                  type: 'link',
                },
              ],
              status: 'to-do',
              hasSubtasks: true,
              subTasksAction: true,
              date,
            }).then(() => {
              return slackRef.collection('subtasks').add({
                title: `${data.fullName}, ${data.email}`,
              })
            })
            this.data.status = 'inactive'
            const positions = this.data.positions
            positions[positions.length - 1].endDate = endDate
            positions[positions.length - 1].reason = this.offboarding.reason
            positions[positions.length - 1].offboardBy = this.$store.state.user.name
            positions[positions.length - 1].offboardDate = date
            firestore.collection('users').doc(this.data.uid)
              .update({
                status: this.data.status,
                mailingListID: '',
                positions,
              })
            openNotification('Ansatt offboardet')
            this.$router.push({ path: '/hr/persons/active' })
          }).catch(err => {
            console.log(err)
            openNotification('Feil ved deaktivering av bruker. IT har mottatt varsel og vil ordne fortløpende')
            sendEmail('it@skygruppen.no', '', `SKYPORTAL: Error while deactivating employee ${data.fullName}`,
            `<html><body><p>Hi,<br>
            An error occured while deactivating ${data.fullName} on SKYPORTAL<br>
            The error that got logged is:<br>
            ${err}<br>
            The error happened on ${this.$store.state.user.fullName}'s user.<br>
            Kind regards<br><br>
            IT Sky Gruppen</p></body></html>`)
          }).finally(() => {
            this.loadingOffboarding = false
          })
      } else {
        this.loadingOffboarding = false
        openNotification('Vennligst legg inn alle obbligatoriske felt')
      }
    },
    generateDismissal(fullName, fullAdress, reason, date) {
      try {
        // Don't forget, that there are CORS-Restrictions. So if you want to run it without a Server in your Browser you need to transform the image to a dataURL
        // Use http://dataurl.net/#dataurlmaker
        var doc = new JsPDF()
        doc.addImage('/resources/images/skycall.png', 'PNG', 140, 10, 55, 25)
        doc.setFontSize(24)
        doc.text('Avskjed', 25, 40)
        doc.setFont('calibri')
        doc.setFontSize(11)
        doc.text(`Arbeidstakers navn: ${fullName} \n${fullAdress}`, 25, 50)
        doc.text(`Lysaker, ${date}`, 150, 60)
        const text1 = `Du avskjediges fra din stilling hos oss med øyeblikkelig fratreden.\n
  Årsaken til avskjeden er: ${reason}\n
  Eter arbeidsmiljøloven plikter vi å opplyse om følgende:`
        const splitTitle1 = doc.splitTextToSize(text1, 150)
        doc.text(25, 80, splitTitle1)
        const text2 = `Arbeidstaker som vil gjøre gjeldende at avskjeden er urettmessig og/eller vil kreve erstatning som følge av dette, kan kreve forhandlinger med arbeidsgiver og reise søksmål, jf. arbeidsmiljøloven (aml) §§ 17-3 og 17-4. Arbeidstaker som vil kreve forhandlinger, må skriftlig underrette arbeidsgiver om dette innen to uker fra avskjeden fant sted. Søksmål må reises innen åtte uker etter forhandlingenes avslutning. \n
  Dersom forhandlinger ikke er holdt, må søksmål reises innen åtte uker etter at avskjeden fant sted. Dersom arbeidstaker bare krever erstatning, er søksmålsfristen seks måneder fra forhandlingenes avslutning. Er forhandlinger ikke holdt, regnes fristen på seks måneder fra avskjeden fant sted. Det følger av arbeidsmiljøloven § 15-11 nr. 3 at arbeidstaker ikke har rett til å fortsette i stillingen ved tvist om avskjed. Etter krav fra arbeidstaker kan retten likevel bestemme at arbeidsforholdet skal fortsette inntil saken er rettskraftig avgjort, dersom søksmål reises innen 8 uker fra avskjeden fant sted eller forhandlingens avslutning.
        `
        const splitTitle2 = doc.splitTextToSize(text2, 150)
        doc.setFont('calibri', 'italic')
        doc.text(25, 110, splitTitle2)
        doc.setFont('calibri', 'normal')
        doc.text(`Arbeidsgiver og rett saksøkt i en eventuell tvist er Skycall AS.\n
  Med hilsen\n \n
  __________________ \n
  Vincent M. Tinella\n
  CFO/CTO\nSkycall AS`, 25, 180)
        // doc.addImage('examples/images/Octonyan.jpg', 'JPEG', 15, 40, 180, 180)
        return doc.output('blob')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style>

</style>
