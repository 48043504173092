<template>
  <div class="row d-flex align-items-center mb-3">
    <div class="col-2 d-flex justify-content-center">
      <a-button style="width: 100px;" type="secondary" @click="switchEmployee('prev')">&lt; Forrige</a-button>
    </div>
    <div class="col-8 d-flex justify-content-center">
      <div class="radio-group-container">
        <a-radio-group class="radio-group" :default-value="selectedTab" @change="changeTab">
          <a-radio-button value="overview">
            Oversikt
          </a-radio-button>
          <a-radio-button value="systems" >
            Brukere
          </a-radio-button>
          <a-radio-button value="roles" v-if="['userAdmin'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
            Tilganger
          </a-radio-button>
          <a-radio-button value="documents" v-if="['HRseeUsersDocuments'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
            Dokumenter
          </a-radio-button>
          <a-radio-button value="log" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
            Hendvendelser
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <div class="col-2 d-flex justify-content-center">
      <a-button style="width: 100px;" type="secondary" @click="switchEmployee('next')">Neste &gt;</a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedTab: String,
  },
  methods: {
    switchEmployee(direction) {
      this.$emit('switchEmployee', direction)
    },
    changeTab(e) {
      this.$emit('changeTab', e.target.value)
    },
  },
}
</script>

<style scoped>
<style scoped>
.radio-group-container {
  display: flex;
  justify-content: center;
}
.radio-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
