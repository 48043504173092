<template>
  <div>
    <div class="card">
      <div class="card-body">
        <users-form :data="data" />
      </div>
    </div>
    <div class="card" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
      <div class="card-body">
        <h5 class="text-black mt-2 mb-3">
          <strong>Avansert</strong>
        </h5>
        <!-- Form to edit user doc id in firebase -->
          <a-form-item label="Firebase UID">
            <a-input v-model="newUid" />
          </a-form-item>
          <a-form-item>
            <a-popconfirm
              @confirm="changeUID(data)"
              title="Er du sikker på at du vil endre UID?"
              content="Dette vil endre brukerens UID i firebase, og kan føre til at brukeren ikke får tilgang til systemet."
              okText="Ja"
              okType="danger"
              cancelText="Nei"
            ><a-button type="primary">Oppdater</a-button></a-popconfirm>
          </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import UsersForm from '@/components/custom/forms/users'
import { firestore } from '@/services/firebase'

export default {
  props: ['data'],
  components: {
    UsersForm,
  },
  data() {
    return {
      newUid: this.data.uid,
    }
  },
  methods: {
    changeUID(data) {
      // Change doc id in firestore
      firestore.collection('users').doc(data.uid).get()
        .then(doc => {
          const docData = doc.data()
          console.log(docData)
          firestore.collection('users').doc(this.newUid).set(docData)
            .then(() => {
              firestore.collection('users').doc(data.uid).delete()
                .then(() => {
                  console.log('Document successfully deleted!')
                  this.data.uid = this.newUid
                  this.$router.push({ path: `/hr/person/${this.newUid}` })
                })
                .catch(error => {
                  console.error('Error removing document: ', error)
                })
            })
            .catch(error => {
              console.error('Error writing document: ', error)
            })
        })
    },
  },
}
</script>

<style>

</style>
