<template>
  <div>
    <div>
      <a-button class="mr-2" style="float: right;" @click="toggleNew" v-if="['admin'].includes(user.role)">
      {{ $t('general.new') }}
      </a-button>
      <h5 class="text-black mt-2 mb-3">
        <strong>Hendvendelser</strong>
      </h5>
      <div v-for="(activity, index) in data.activities" :key="index">
        <div class="mb-2">
          <strong>{{ activity.type }}</strong><br>
          Dato: {{ activity.date }}<br>
          Oprettet av: {{ activity.sentBy.name }}<br>
          Kommentar: <br>{{ activity.comment }}<br>
          <a-button style="float: right; bottom: 20px;" @click="deleteActivity(index)" v-if="['admin'].includes(user.role)">
          Slett Henvedelse
          </a-button>
          <div class="form-actions" v-if="data.activities.length != (index+1)"/>
        </div>
      </div>
      <span v-if="!data.activities">Ingen hendvendelser forlebi</span>
      <span v-else-if="data.activities.length == 0">Ingen hendvendelser forlebi</span>
      <div v-if="newActivity">
        <h5 class="text-black mt-5 mb-3">
          <strong>Ny henvendelse</strong>
        </h5>
        <a-radio-group v-model="activity.type">
          <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="Forsinkelser / Fravær">
              <a-radio :style="radioStyle" :value="'Forsinkelse uten varsel'">
              Forsinkelse uten varsel
              </a-radio>
              <a-radio :style="radioStyle" :value="'Forsinkelse uten gyldig grunn'">
              Forsinkelse uten gyldig grunn
              </a-radio>
              <a-radio :style="radioStyle" :value="'Fravær uten varsel'">
              Fravær uten varsel
              </a-radio>
              <a-radio :style="radioStyle" :value="'Fravær uten gyldig grunn'">
              Fravær uten gyldig grunn
              </a-radio>
          </a-tab-pane>
          <a-tab-pane key="2" tab="Skriftlige varsler"  v-if="['admin'].includes(user.role)" force-render>
              <a-radio :style="radioStyle" :value="'Gjentatte fravær uten varsel'">
              Gjentatte fravær uten varsel
              </a-radio>
              <a-radio :style="radioStyle" :value="'Dårlig handling'">
              Dårlig handling
              </a-radio>
              <a-radio :style="radioStyle" :value="'Dårlig resultater'">
              Dårlig resultater
              </a-radio>
          </a-tab-pane>
          <a-tab-pane key="3" tab="Oppsigelse"  v-if="['admin'].includes(user.role)">
              <a-radio :style="radioStyle" :value="'Ansatten har sagt opp selv'" >
              Ansatten har sagt opp selv
              </a-radio>
              <a-radio :style="radioStyle" :value="'Oppsigelse under prøvetid pga. dårlig oppmøte'">
              Oppsigelse under prøvetid pga. dårlig oppmøte
              </a-radio>
              <a-radio :style="radioStyle" :value="'Oppsigelse utenfor prøvetid pga. dårlig oppmøte'">
              Oppsigelse utenfor prøvetid pga. dårlig oppmøte
              </a-radio>
              <a-radio :style="radioStyle" :value="'Oppsigelse under prøvetid pga. dårlig resultater'">
              Oppsigelse under prøvetid pga. dårlig resultater
              </a-radio>
          </a-tab-pane>
          <a-tab-pane key="5" tab="Egendefinert">
              <a-radio :style="radioStyle" :value="'custom'" >
              Egendefinert henvedelse
              </a-radio>
          </a-tab-pane>
          </a-tabs>
        </a-radio-group>
        <div class="row" v-if="activity.type === 'custom'">
          <div class="col-md-6">
            <a-form-item label="Tittel på henvendelse">
            <a-input placeholder="Tittel" v-model="customType"/>
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
          <a-form-item label="Kommentar" :required="true">
            <a-textarea placeholder="..." :rows="4" v-model="activity.comment"/>
          </a-form-item>
          </div>
        </div>
        <div class="row">
        <div class="col-lg-6">
            <a-form-item label="Dato" :required="true">
            <a-date-picker :default-value="moment(new Date(), 'YYYY-MM-DD')"  v-model="activity.date"/>
            </a-form-item>
        </div>
        </div>
        <a-button style="width: 200px;" type="primary"  class="mr-3 mt-3" @click="createActivity" :loading="loading">Opprett Ny Henvendelse</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/firestore'
import moment from 'moment'
import { mapState } from 'vuex'

import { openNotification } from '@/services/powerAutomate'

const systems = ['Microsoft 365', 'Leaddesk Fjordkraft', 'Talkmore Privat']

export default {
  props: ['data'],
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      systems,
      leadDesk: false,
      office365: false,
      talkmoreB2C: false,
      leadDeskUser: '',
      companyEmail: '',
      newActivity: false,
      activity: {},
      customType: '',
      loading: false,
    }
  },
  methods: {
    moment,
    toggleNew() {
      this.newActivity = !this.newActivity
    },
    createActivity() {
      this.loading = true
      const data = this.data
      const activity = this.activity
      let lastActivityID = 0
      activity.type = activity.type === 'custom' ? this.customType : activity.type
      activity.date = moment(activity.date).format('YYYY-MM-DD')
      activity.sentBy = {
        name: this.user.name,
        uid: this.user.id,
      }
      firebase.firestore().collection('variables').doc('employeeID').get()
        .then(doc => {
          lastActivityID = String(Number(doc.data().lastActivityID) + 1)
          this.activity.id = lastActivityID
          if (data.activities) data.activities.push(activity)
          if (!data.activities) {
            data.activities = []
            data.activities[0] = activity
          }
          return firebase.firestore().collection('users').doc(data.uid).update(data)
        }).then(() => {
          openNotification('Stilling opprettett')
          this.data = data
          return firebase.firestore().collection('variables').doc('employeeID').update({ lastActivityID })
        }).then(() => {
          return true
        }).catch(err => {
          openNotification('Feil ved oprettelse av stilling', 'Vennligst prøv igjen')
          console.log(err)
        }).finally(() => {
          this.newActivity = false
          this.loading = false
        })
    },
    deleteActivity(i) {
      const data = this.data
      data.activities.splice(i, 1)
      firebase.firestore().collection('users').doc(this.data.uid).update(data).then(() => {
        openNotification('Henvendelse slettet')
        this.data = data
      }).catch(err => openNotification('Feil ved sletting av henvendelse', err))
    },
    handleBlur() {
      console.log('blur')
    },
    handleFocus() {
      console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style scoped>
  .ant-input.validfield {
    border-color: green!important
  }
  .ant-input.invalidfield {
    border-color: red!important
  }
</style>
